<template>
  <b-col v-if="!hiddenInput.medal" :lg="colUserType" class="px-2">
    <label class="font-weight-bold ft-s-14" for="model-no">{{
      labelModelNo
    }}</label>

    <b-input-group id="model-no" class="style-input-group">
      <b-form-input
        v-model="modelNo"
        @keyup.enter="onModelNoSearched"
      ></b-form-input>
      
      <b-input-group-append>
        <b-button
          variant="primary"
          @click="onModelNoSearched"
        >ค้นหา</b-button>
      </b-input-group-append>
    </b-input-group>
  </b-col>
</template>

<script>
export default {
  props: {
    vModel: Object,

    hiddenInput: {
      type: Object,
      default: () => ({}),
    },

    colUserType: {
      type: Number,
      default: 3,
    },

    labelModelNo: {
      type: String,
      default: "รุ่นครูตำรวจแดร์",
    },
  },

  model: {
    prop: "vModel",
    event: "change",
  },

  data() {
    return {
      modelNo: "",
    };
  },

  computed: {
    form: {
      get() {
        return this.vModel;
      },

      set(v) {
        this.$emit("change", v);
      },
    },
  },

  methods: {
    onModelNoSearched() {
      this.$set(this.form, "model_no", this.modelNo);
    },
  },
};
</script>

<style lang="scss" scoped>
.style-input-group {
  input {
    padding: 0.53rem 0.83rem
  }

  button {
    padding: 0.25rem 1.1rem;
    font-size: 0.83125rem;
    line-height: 1.5;
  }
}
</style>

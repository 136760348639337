<template>
  <b-form>
    <div class="d-flex flex-row flex-wrap">
      <slot name="before"></slot>

      <div
        v-if="!hiddenInput.startDate"
        class="col-12 px-2 mb-2 mt-2 mt-lg-0"
        :class="{ [`col-lg-${colStartDate}`]: true }"
      >
        <label class="font-weight-bold ft-s-14" for="startDate">{{
          labelStartDate
        }}</label>
        <datepicker
          ref="datepicker"
          id="startDate"
          input-class="form-control"
          required
          :language="th"
          :format="formatBuddhistBirthDate"
          :disabled="disabled"
          clear-button
          v-model="startDate"
          @selected="onStartDateChanged"
        ></datepicker>
      </div>
      <div
        v-if="!hiddenInput.endDate"
        class="col-12 px-2 mb-2"
        :class="{ [`col-lg-${colEndDate}`]: true }"
      >
        <label class="font-weight-bold ft-s-14" for="endDate">{{
          labelEndDate
        }}</label>
        <datepicker
          ref="datepicker"
          id="endDate"
          input-class="form-control"
          required
          :language="th"
          :format="formatBuddhistBirthDate"
          :disabled="disabled"
          clear-button
          v-model="endDate"
          @selected="onEndDateChanged"
        ></datepicker>
      </div>

      <slot></slot>
    </div>
  </b-form>
</template>

<script>
import { th } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";
import { formatBuddhistBirthDate } from "../../helpers/datepicker-helper";

export default {
  props: {
    vModel: {
      type: Object,
      required: true,
      default: () => ({
        startDate: null,
        endDate: null,
      }),
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    colStartDate: {
      type: Number,
      default: 4,
    },

    colEndDate: {
      type: Number,
      default: 4,
    },

    hiddenInput: {
      type: Object,
      default: () => ({}),
    },

    labelStartDate: {
      type: String,
      default: "วันที่เริ่ม",
    },

    labelEndDate: {
      type: String,
      default: "วันที่สิ้นสุด",
    },
  },

  components: {
    Datepicker,
  },

  model: {
    prop: "vModel",
    event: "change",
  },

  data() {
    return {
      th,
      startDate: this.vModel.startDate || null,
      endDate: this.vModel.endDate || null,
    };
  },

  computed: {
    $vModel: {
      get() {
        return this.vModel;
      },

      set(v) {
        this.$emit("change", v);

        return this;
      },
    },
  },

  methods: {
    formatBuddhistBirthDate,

    onStartDateChanged(date) {
      this.updateDate(date, "startDate");
    },

    onEndDateChanged(date) {
      this.updateDate(date, "endDate");
    },

    updateDate(date, field) {
      let value = null;

      if (date) {
        const dObj = new Date(date.getTime());

        dObj.setHours(0);
        dObj.setMinutes(0);
        dObj.setSeconds(0);

        value = dObj.toISOString();
      }

      this.$set(this.$vModel, field, value);
    },
  },
};
</script>

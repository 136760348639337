<template>
  <b-col v-if="!hiddenInput.user_status" :lg="colUserType" class="px-2">
    <label class="font-weight-bold ft-s-14" for="user_status">{{
      labelUserType
    }}</label>

    <v-select
      id="user_status"
      v-model="userStatus"
      :options="userStatuss"
      :clearable="isNotDefaultValue(userStatus.label)"
      @input="onInput($event)"
    ></v-select>
  </b-col>
</template>

<script>
import vSelect from "vue-select";

export default {
  props: {
    vModel: Object,

    defaultId: {
      type: [Number, String],
      default: null,
    },

    defaultValue: {
      type: String,
      default: "ทั้งหมด",
    },

    hiddenInput: {
      type: Object,
      default: () => ({}),
    },

    colUserType: {
      type: Number,
      default: 3,
    },

    labelUserType: {
      type: String,
      default: "สถานะ",
    },
  },

  model: {
    prop: "vModel",
    event: "change",
  },

  components: {
    vSelect,
  },

  data() {
    return {
      userStatuss: [
        {
          id: null,
          label: this.defaultValue,
        },
        {
          id: "เข้าสอน",
          label: "เข้าสอน",
        },
        {
          id: "ไม่เข้าสอน",
          label: "ไม่เข้าสอน",
        },
        {
          id: "เกษียณ",
          label: "เกษียณ",
        },
        {
          id: "เสียชีวิต",
          label: "เสียชีวิต",
        },
        {
          id: "ลาออก",
          label: "ลาออก",
        },
      ],

      userStatus: {
        id: this.defaultId,
        label: this.defaultValue,
      },
    };
  },

  watch: {
    userStatus: "onUserTypeChanged",
  },

  computed: {
    form: {
      get() {
        return this.vModel;
      },

      set(v) {
        this.$emit("change", v);
      },
    },
  },

  methods: {
    isNotDefaultValue(value) {
      return value !== this.defaultValue;
    },

    onInput(value) {
      if (!value) {
        this.userStatus = { id: this.defaultId, label: this.defaultValue };
      }
    },

    onUserTypeChanged(userStatus) {
      this.form = {
        ...this.form,
        user_status: userStatus.id,
      };
    },
  },
};
</script>
